
// ページ内アンカーリンクのスクロールエフェクト
// ヘッダーが固定の場合はheaderHightにヘッダーの高さを設定(PC/SP要設定)
jQuery(function($) {
    var headerHeight = 0; // ヘッダー追従でない場合
	// var headerHeight = $('header').innerHeight(); // ヘッダー追従の場合
    $('a[href^="#"]').click(function(){
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        if ( target.length > 0 ) {
            var position = target.offset().top - headerHeight;
            $("html, body").animate({scrollTop:position}, 550, "swing");
            return false;
        }
    });
})

// 追従CV
$(document).ready(function(){
    $("#floating_area").hide();
    $(window).on("scroll", function() {
        if ($(this).scrollTop() > 500) {
            $("#floating_area").fadeIn("normal");
        } else {
            $("#floating_area").fadeOut("normal");
        }
        scrollHeight = $(document).height(); //ドキュメントの高さ
        scrollPosition = $(window).height() + $(window).scrollTop(); //現在地
        footHeight = $("footer").innerHeight(); //footerの高さ（＝止めたい位置）
        if ( scrollHeight - scrollPosition  <= footHeight ) { //ドキュメントの高さと現在地の差がfooterの高さ以下になったら
            $("#floating_area").css({
                "position":"absolute",
            });
        } else { //それ以外の場合は
            $("#floating_area").css({
                "position":"fixed", //固定表示
            });
        }
    });
});

// ページトップボタンの表示
jQuery(function($) {
	
	if ( $('#pageup').length > 0 ) {
		var topBtn = $('#pageup');    
		topBtn.css("display", "none");
		//スクロールが100に達したらボタン表示
		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				topBtn.fadeIn();
			} else {
				topBtn.fadeOut();
			}
		});
		//スクロールしてトップ
		topBtn.click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 500);
			return false;
		});
	}
});

// スライダー
jQuery(function($) {
	if ( $('.sample_slider').length > 0 ) {

		const infiniteSlider = new Swiper(".sample_slider", {
		  loop: true,
		  slidesPerView: "2",
		  speed: 6000,
		  spaceBetween: 10,
		  loopedSlides: 3,
		  centeredSlides: true,
		  autoplay: {
		    delay: 0,
		    disableOnInteraction: false,
		    simulateTouch: false,
		  },
		});

	}
});

// アコーディオンの起動
jQuery(function($) {
	
	if ( $('.faq_list').length > 0 ) {
		
		$(".faq_list h3").click(function(){
			$(this).next(".detail_wrapper").slideToggle();
			$(this).toggleClass("open");
		});
	}
});



//scroll_effect
$(window).scroll(function () {
	var scrollAnimationElm = document.querySelectorAll('.ttl_image');
	var scrollAnimationFunc = function () {
		for (var i = 0; i < scrollAnimationElm.length; i++) {
			var triggerMargin = 100;
			if (window.innerHeight > scrollAnimationElm[i].getBoundingClientRect().top + triggerMargin) {
				scrollAnimationElm[i].classList.add('on');
			}
		}
	}
	window.addEventListener('load', scrollAnimationFunc);
	window.addEventListener('scroll', scrollAnimationFunc);
});
